.PrivacyPolicy {
    padding: 16px;
    margin: 128px 0px;
}

.PrivacyContent {
    text-align: left;
    max-width: 738px;
    padding: 24px;
    background-color: #f5f5f5;
    border-radius: 24px;
    box-sizing: border-box;
}

.PrivacyContent p {
    color: rgba(0,0,0,0.7);
}

.PrivacyContent h1 h2 {
    margin: 0px;
}

@media screen and (min-width: 738px) {
    .PrivacyPolicy {
        padding: 0px;
    }
}