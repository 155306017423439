.Hero-content {
    display: flex;
    flex-direction: column;
}

.Hero {
    margin-top: 128px;
    background-color: #f5f5f5;
    border-radius: 24px;
    padding: 24px 24px 0px 24px;
}

.Hero h1 {
    font-size: 38px;
    font-weight: 500;
    line-height: 100%;
    color: #000;
    text-align: left;
    margin:0px;
}

.Hero-text-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.Hero-conditional-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
}

.Hero-subtext-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;
}

.Hero-subtext {
    font-size: 13px;
    columns: #000;
}

a {
    color: #000;
}

.TestFlight-content {
    text-align: left;
}

.Hero-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Hero-image {
    width: 100%;
    max-width: 500px;
    /* padding-top: 50px; */
}

.Hero-conditional {
    margin-bottom: 32px;
    width: 100%;
}

.Hero-button {
    font-weight: 600;
    background-color: #000;
    color: #fff;
    border-radius: 100px;
    border: 0;
}

.Hero-button-big {
    padding: 16px;
    font-size: 18px;
    width: 100%;
    box-shadow: 0 -2px 30px rgba(147, 197, 251, 0.1),
                0 2px 30px rgba(58, 207, 213, 0.2),
                0 2px 28px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 500px) {
    .Hero-conditional {
        margin-bottom: 0px;
    }
}


@media screen and (min-width: 738px) {
    .Hero-content {
        flex-direction: row;
        align-items: start;
    }

    .Hero-text-container {
        width: 100%;
    }

    .Hero-image {
        width: 100%;
        max-width: 363px;
    }
    
}