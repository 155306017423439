.Logo {
    width: auto;
    height: 24px;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 50%;
    height: 36px;
    transform: translateX(-50%);
    max-width: 738px;
    width: calc(100% - 32px);
    padding: 16px 16px;
    background: linear-gradient(to bottom, 
        rgba(255,255,255,1) 0%,
        rgba(255,255,255,.8) 60%, 
        rgba(255,255,255,0) 100%
    );
    z-index: 1000;
}

.Header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: start;
}

.LogoText {
    width: auto;
    height: 18px;
}

.CTA-container {
    position: relative;
    z-index: 1001;
    cursor: pointer;
}

.additional-text {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.8);
    padding: 2px 6px;
    border-radius: 4px;
}

.QRCode-hover {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    opacity: 0;
    transform: translateY(-20px) scale(0);
    transform-origin: top right;
    transition: opacity 0.2s ease, transform 0.2s ease;
    pointer-events: none;
    left: 12%;
}

.QRCode-hover.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
    pointer-events: auto;
    left: 12%;
}

.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    background-color: rgba(255, 255, 255, 0);
    z-index: 999;
    transition: backdrop-filter 0.3s ease, background-color 0.3s ease;
    pointer-events: none;
}

@media screen and (min-width: 500px) {
    .blur-overlay.active {
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.5);
    }
}

@media screen and (min-width: 738px) {
    .Header {
        width: 100%;
        padding: 16px 0px;
    }
}