.Feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 24px;
    background-color: #f5f5f5;
    border-radius: 24px;
    max-width: 313px;
    width: 100%;
    gap: 16px;
    min-width: min(100%, 200px); /* Add this line */
    text-align: left;
}

.Feature-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    color: #000;
    margin: 0px;
    text-align: left;
    width: 100%;
}

p {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.8);
    margin: 0px;
}

.Feature img {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 300px; /* Adjust this value as needed */
}

.FeatureVideo {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 300px;
}