.JustDragIt {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.JustDragIt-title {
    text-decoration: line-through;
    color: #8c8c8c;

}

.JustDragIt-title, .JustDragItFinal {
    font-size: 34px;
    line-height: 130%;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    margin: 0px;
    text-align: left;
}

.JustDragItFinal {
    color: #000;
}

.DragSymbol {
    height: 20px;
    width: auto;
}

.JustDragIt-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

@media screen and (min-width: 738px) {
.DragSymbol {
    height: 40px;
}

.JustDragIt-title, .JustDragItFinal {
    font-size: 34px;
}

}