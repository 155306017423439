.Letter p {
    font-size: 24px;
    text-align: left;
    line-height: 140%;
    color: #8c8c8c;
}

.GlobezonesSentenceLogo {
    height: 24px;
    width: auto;
    opacity: 45%;
    margin-right: 6px;
}

.Letter {
    margin-bottom: 100px;
}

.LetterLink {
    color: #8c8c8c;
}

@media screen and (min-width: 738px) {
    .Letter p {
        font-size: 34px;
    }
}